import React from 'react';
import Song from './../assets/audio/yoshi/MainSong.mp3';
import Origin from './../assets/audio/yoshi/Origin.mp3';
import x0 from './../assets/audio/yoshi/x0.mp3';
import y0 from './../assets/audio/yoshi/y0.mp3';
import RunLeft1 from './../assets/audio/yoshi/RunLeft1.mp3';
import RunLeft2 from './../assets/audio/yoshi/RunLeft2.mp3';
import RunLeft3 from './../assets/audio/yoshi/RunLeft3.mp3';
import RunLeft4 from './../assets/audio/yoshi/RunLeft4.mp3';
import RunLeft5 from './../assets/audio/yoshi/RunLeft5.mp3';
import RunLeft6 from './../assets/audio/yoshi/RunLeft6.mp3';
import RunLeft7 from './../assets/audio/yoshi/RunLeft7.mp3';
import RunLeft8 from './../assets/audio/yoshi/RunLeft8.mp3';
import RunLeft9 from './../assets/audio/yoshi/RunLeft9.mp3';
import RunLeft10 from './../assets/audio/yoshi/RunLeft10.mp3';
import RunRight1 from './../assets/audio/yoshi/RunRight1.mp3';
import RunRight2 from './../assets/audio/yoshi/RunRight2.mp3';
import RunRight3 from './../assets/audio/yoshi/RunRight3.mp3';
import RunRight4 from './../assets/audio/yoshi/RunRight4.mp3';
import RunRight5 from './../assets/audio/yoshi/RunRight5.mp3';
import RunRight6 from './../assets/audio/yoshi/RunRight6.mp3';
import RunRight7 from './../assets/audio/yoshi/RunRight7.mp3';
import RunRight8 from './../assets/audio/yoshi/RunRight8.mp3';
import RunRight9 from './../assets/audio/yoshi/RunRight9.mp3';
import RunRight10 from './../assets/audio/yoshi/RunRight10.mp3';
import Fall1 from './../assets/audio/yoshi/Fall1.mp3';
import Fall2 from './../assets/audio/yoshi/Fall2.mp3';
import Fall3 from './../assets/audio/yoshi/Fall3.mp3';
import Fall4 from './../assets/audio/yoshi/Fall4.mp3';
import Fall5 from './../assets/audio/yoshi/Fall5.mp3';
import Fall6 from './../assets/audio/yoshi/Fall6.mp3';
import Fall7 from './../assets/audio/yoshi/Fall7.mp3';
import Fall8 from './../assets/audio/yoshi/Fall8.mp3';
import Fall9 from './../assets/audio/yoshi/Fall9.mp3';
import Fall10 from './../assets/audio/yoshi/Fall10.mp3';
import Fly1 from './../assets/audio/yoshi/Fly1.mp3';
import Fly2 from './../assets/audio/yoshi/Fly2.mp3';
import Fly3 from './../assets/audio/yoshi/Fly3.mp3';
import Fly4 from './../assets/audio/yoshi/Fly4.mp3';
import Fly5 from './../assets/audio/yoshi/Fly5.mp3';
import Fly6 from './../assets/audio/yoshi/Fly6.mp3';
import Fly7 from './../assets/audio/yoshi/Fly7.mp3';
import Fly8 from './../assets/audio/yoshi/Fly8.mp3';
import Fly9 from './../assets/audio/yoshi/Fly9.mp3';
import Fly10 from './../assets/audio/yoshi/Fly10.mp3';
import Cute1 from './../assets/audio/yoshi/Cute1.mp3';
import Cute2 from './../assets/audio/yoshi/Cute2.mp3';
import Cute3 from './../assets/audio/yoshi/Cute3.mp3';
import Cute4 from './../assets/audio/yoshi/Cute4.mp3';
import Cute5 from './../assets/audio/yoshi/Cute5.mp3';
import Rhyme1 from './../assets/audio/yoshi/Rhyme1.mp3';
import Rhyme2 from './../assets/audio/yoshi/Rhyme2.mp3';
import Rhyme3 from './../assets/audio/yoshi/Rhyme3.mp3';
import Rhyme4 from './../assets/audio/yoshi/Rhyme4.mp3';
import Rhyme5 from './../assets/audio/yoshi/Rhyme5.mp3';
import Rhyme6 from './../assets/audio/yoshi/Rhyme6.mp3';
import Rhyme7 from './../assets/audio/yoshi/Rhyme7.mp3';
import Rhyme8 from './../assets/audio/yoshi/Rhyme8.mp3';
import Rhyme9 from './../assets/audio/yoshi/Rhyme9.mp3';
import Rhyme10 from './../assets/audio/yoshi/Rhyme10.mp3';

import useSound from 'use-sound';

const VOLUME = 0.5;

export default function useYoshiAudio() {
  const [mainSongIsPlaying, setMainSongIsPlaying] = React.useState(false);
  const [playMainSong, { pause: pauseMainSong }] = useSound(Song, {
    volume: VOLUME,
    onend: () => setMainSongIsPlaying(false),
    onplay: () => setMainSongIsPlaying(true),
    onpause: () => setMainSongIsPlaying(false),
  });

  const [playOrigin, { duration: zeroDuration }] = useSound(Origin, { volume: VOLUME });
  const [playX0, { duration: x0Duration }] = useSound(x0, { volume: VOLUME });
  const [playY0, { duration: y0Duration }] = useSound(y0, { volume: VOLUME });

  //Run left
  const [playRunLeft1, { duration: runLeft1Duration }] = useSound(RunLeft1, { volume: VOLUME });
  const [playRunLeft2, { duration: runLeft2Duration }] = useSound(RunLeft2, { volume: VOLUME });
  const [playRunLeft3, { duration: runLeft3Duration }] = useSound(RunLeft3, { volume: VOLUME });
  const [playRunLeft4, { duration: runLeft4Duration }] = useSound(RunLeft4, { volume: VOLUME });
  const [playRunLeft5, { duration: runLeft5Duration }] = useSound(RunLeft5, { volume: VOLUME });
  const [playRunLeft6, { duration: runLeft6Duration }] = useSound(RunLeft6, { volume: VOLUME });
  const [playRunLeft7, { duration: runLeft7Duration }] = useSound(RunLeft7, { volume: VOLUME });
  const [playRunLeft8, { duration: runLeft8Duration }] = useSound(RunLeft8, { volume: VOLUME });
  const [playRunLeft9, { duration: runLeft9Duration }] = useSound(RunLeft9, { volume: VOLUME });
  const [playRunLeft10, { duration: runLeft10Duration }] = useSound(RunLeft10, { volume: VOLUME });

  //Run right
  const [playRunRight1, { duration: runRight1Duration }] = useSound(RunRight1, { volume: VOLUME });
  const [playRunRight2, { duration: runRight2Duration }] = useSound(RunRight2, { volume: VOLUME });
  const [playRunRight3, { duration: runRight3Duration }] = useSound(RunRight3, { volume: VOLUME });
  const [playRunRight4, { duration: runRight4Duration }] = useSound(RunRight4, { volume: VOLUME });
  const [playRunRight5, { duration: runRight5Duration }] = useSound(RunRight5, { volume: VOLUME });
  const [playRunRight6, { duration: runRight6Duration }] = useSound(RunRight6, { volume: VOLUME });
  const [playRunRight7, { duration: runRight7Duration }] = useSound(RunRight7, { volume: VOLUME });
  const [playRunRight8, { duration: runRight8Duration }] = useSound(RunRight8, { volume: VOLUME });
  const [playRunRight9, { duration: runRight9Duration }] = useSound(RunRight9, { volume: VOLUME });
  const [playRunRight10, { duration: runRight10Duration }] = useSound(RunRight10, {
    volume: VOLUME,
  });

  //Fall
  const [playFall1, { duration: fall1Duration }] = useSound(Fall1, { volume: VOLUME });
  const [playFall2, { duration: fall2Duration }] = useSound(Fall2, { volume: VOLUME });
  const [playFall3, { duration: fall3Duration }] = useSound(Fall3, { volume: VOLUME });
  const [playFall4, { duration: fall4Duration }] = useSound(Fall4, { volume: VOLUME });
  const [playFall5, { duration: fall5Duration }] = useSound(Fall5, { volume: VOLUME });
  const [playFall6, { duration: fall6Duration }] = useSound(Fall6, { volume: VOLUME });
  const [playFall7, { duration: fall7Duration }] = useSound(Fall7, { volume: VOLUME });
  const [playFall8, { duration: fall8Duration }] = useSound(Fall8, { volume: VOLUME });
  const [playFall9, { duration: fall9Duration }] = useSound(Fall9, { volume: VOLUME });
  const [playFall10, { duration: fall10Duration }] = useSound(Fall10, { volume: VOLUME });

  //Fly
  const [playFly1, { duration: fly1Duration }] = useSound(Fly1, { volume: VOLUME });
  const [playFly2, { duration: fly2Duration }] = useSound(Fly2, { volume: VOLUME });
  const [playFly3, { duration: fly3Duration }] = useSound(Fly3, { volume: VOLUME });
  const [playFly4, { duration: fly4Duration }] = useSound(Fly4, { volume: VOLUME });
  const [playFly5, { duration: fly5Duration }] = useSound(Fly5, { volume: VOLUME });
  const [playFly6, { duration: fly6Duration }] = useSound(Fly6, { volume: VOLUME });
  const [playFly7, { duration: fly7Duration }] = useSound(Fly7, { volume: VOLUME });
  const [playFly8, { duration: fly8Duration }] = useSound(Fly8, { volume: VOLUME });
  const [playFly9, { duration: fly9Duration }] = useSound(Fly9, { volume: VOLUME });
  const [playFly10, { duration: fly10Duration }] = useSound(Fly10, { volume: VOLUME });

  //Rhymes
  const [playRhyme1, { duration: rhyme1Duration }] = useSound(Rhyme1, { volume: VOLUME });
  const [playRhyme2, { duration: rhyme2Duration }] = useSound(Rhyme2, { volume: VOLUME });
  const [playRhyme3, { duration: rhyme3Duration }] = useSound(Rhyme3, { volume: VOLUME });
  const [playRhyme4, { duration: rhyme4Duration }] = useSound(Rhyme4, { volume: VOLUME });
  const [playRhyme5, { duration: rhyme5Duration }] = useSound(Rhyme5, { volume: VOLUME });
  const [playRhyme6, { duration: rhyme6Duration }] = useSound(Rhyme6, { volume: VOLUME });
  const [playRhyme7, { duration: rhyme7Duration }] = useSound(Rhyme7, { volume: VOLUME });
  const [playRhyme8, { duration: rhyme8Duration }] = useSound(Rhyme8, { volume: VOLUME });
  const [playRhyme9, { duration: rhyme9Duration }] = useSound(Rhyme9, { volume: VOLUME });
  const [playRhyme10, { duration: rhyme10Duration }] = useSound(Rhyme10, { volume: VOLUME });

  //Cute sounds
  const [playCute1, { duration: cute1Duration }] = useSound(Cute1, { volume: VOLUME });
  const [playCute2, { duration: cute2Duration }] = useSound(Cute2, { volume: VOLUME });
  const [playCute3, { duration: cute3Duration }] = useSound(Cute3, { volume: VOLUME });
  const [playCute4, { duration: cute4Duration }] = useSound(Cute4, { volume: VOLUME });
  const [playCute5, { duration: cute5Duration }] = useSound(Cute5, { volume: VOLUME });

  return {
    mainSong: {
      isPlaying: mainSongIsPlaying,
      play: playMainSong,
      pause: pauseMainSong,
    },
    origin: {
      play: playOrigin,
      text: 'Stay still, zero says chill.',
      duration: zeroDuration,
    },
    zero: {
      x: {
        play: playX0,
        duration: x0Duration,
        text: "Don't go left, don't go right.",
      },
      y: {
        play: playY0,
        duration: y0Duration,
        text: "Don't fly, don't fall.",
      },
    },
    right: [
      { play: playRunRight1, duration: runRight1Duration, text: 'Run right 1' },
      { play: playRunRight2, duration: runRight2Duration, text: 'Run right 2' },
      { play: playRunRight3, duration: runRight3Duration, text: 'Run right 3' },
      { play: playRunRight4, duration: runRight4Duration, text: 'Run right 4' },
      { play: playRunRight5, duration: runRight5Duration, text: 'Run right 5' },
      { play: playRunRight6, duration: runRight6Duration, text: 'Run right 6' },
      { play: playRunRight7, duration: runRight7Duration, text: 'Run right 7' },
      { play: playRunRight8, duration: runRight8Duration, text: 'Run right 8' },
      { play: playRunRight9, duration: runRight9Duration, text: 'Run right 9' },
      { play: playRunRight10, duration: runRight10Duration, text: 'Run right 10' },
    ],
    left: [
      { play: playRunLeft1, duration: runLeft1Duration, text: 'Run left 1' },
      { play: playRunLeft2, duration: runLeft2Duration, text: 'Run left 2' },
      { play: playRunLeft3, duration: runLeft3Duration, text: 'Run left 3' },
      { play: playRunLeft4, duration: runLeft4Duration, text: 'Run left 4' },
      { play: playRunLeft5, duration: runLeft5Duration, text: 'Run left 5' },
      { play: playRunLeft6, duration: runLeft6Duration, text: 'Run left 6' },
      { play: playRunLeft7, duration: runLeft7Duration, text: 'Run left 7' },
      { play: playRunLeft8, duration: runLeft8Duration, text: 'Run left 8' },
      { play: playRunLeft9, duration: runLeft9Duration, text: 'Run left 9' },
      { play: playRunLeft10, duration: runLeft10Duration, text: 'Run left 10' },
    ],
    up: [
      { play: playFly1, duration: fly1Duration, text: 'Fly up 1' },
      { play: playFly2, duration: fly2Duration, text: 'Fly up 2' },
      { play: playFly3, duration: fly3Duration, text: 'Fly up 3' },
      { play: playFly4, duration: fly4Duration, text: 'Fly up 4' },
      { play: playFly5, duration: fly5Duration, text: 'Fly up 5' },
      { play: playFly6, duration: fly6Duration, text: 'Fly up 6' },
      { play: playFly7, duration: fly7Duration, text: 'Fly up 7' },
      { play: playFly8, duration: fly8Duration, text: 'Fly up 8' },
      { play: playFly9, duration: fly9Duration, text: 'Fly up 9' },
      { play: playFly10, duration: fly10Duration, text: 'Fly up 10' },
    ],
    down: [
      { play: playFall1, duration: fall1Duration, text: 'Fall down 1' },
      { play: playFall2, duration: fall2Duration, text: 'Fall down 2' },
      { play: playFall3, duration: fall3Duration, text: 'Fall down 3' },
      { play: playFall4, duration: fall4Duration, text: 'Fall down 4' },
      { play: playFall5, duration: fall5Duration, text: 'Fall down 5' },
      { play: playFall6, duration: fall6Duration, text: 'Fall down 6' },
      { play: playFall7, duration: fall7Duration, text: 'Fall down 7' },
      { play: playFall8, duration: fall8Duration, text: 'Fall down 8' },
      { play: playFall9, duration: fall9Duration, text: 'Fall down 9' },
      { play: playFall10, duration: fall10Duration, text: 'Fall down 10' },
    ],
    rhyme: [
      { play: playRhyme1, duration: rhyme1Duration, text: 'Then we are done!' },
      { play: playRhyme2, duration: rhyme2Duration, text: 'That is what we do!' },
      { play: playRhyme3, duration: rhyme3Duration, text: 'Land where the lines agree!' },
      { play: playRhyme4, duration: rhyme4Duration, text: 'Then do no more!' },
      { play: playRhyme5, duration: rhyme5Duration, text: "That's where we arrive." },
      { play: playRhyme6, duration: rhyme6Duration, text: 'Find where the lines kiss.' },
      { play: playRhyme7, duration: rhyme7Duration, text: 'Stop at the intersection.' },
      { play: playRhyme8, duration: rhyme8Duration, text: "Now we've found the coordinate." },
      { play: playRhyme9, duration: rhyme9Duration, text: 'Land where we intertwine.' },
      { play: playRhyme10, duration: rhyme10Duration, text: 'No need to go again.' },
    ],
    cute: [
      { play: playCute1, duration: cute1Duration },
      { play: playCute2, duration: cute2Duration },
      { play: playCute3, duration: cute3Duration },
      { play: playCute4, duration: cute4Duration },
      { play: playCute5, duration: cute5Duration },
    ],
  };
}
